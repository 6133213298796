<!-- 详情弹窗 -->
<template>
  <a-modal
    title="订单详情"
    :width="680"
    :visible="visible"
    :footer="null"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 4 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 20 }, xs: { span: 18 } }"
    >
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title big"
            >商品基本信息</a-form-item
          >
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 商品名称 -->
          <a-form-item label="商品名称：">
            <div>
              {{ data.goodsName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品特点 -->
          <a-form-item label="商品特点：">
            <div>
              {{ data.sellingPoint }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 商品积分 -->
          <a-form-item label="商品积分：" :labelCol="{ span: 8, offset: 0 }">
            <div>
              {{ data.unitPoint }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 实付积分 -->
          <a-form-item label="实付积分：" :labelCol="{ span: 8, offset: 0 }">
            <div>
              {{ data.payPoints }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 商品数量 -->
          <a-form-item label="商品数量：" :labelCol="{ span: 8, offset: 0 }">
            <div>
              {{ data.goodsCount }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 运费金额 -->
          <a-form-item label="运费金额：" :labelCol="{ span: 8, offset: 0 }">
            <div>
              {{ data.expressPriceName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 商品图片 -->
          <a-form-item label="商品图片：">
            <div>
              <a-image
                alt="example"
                :width="100"
                :height="100"
                style="padding: 0px 10px 10px 0px"
                :src="data.goodsMainPic"
              />
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title big">订单信息</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 订单编号 -->
          <a-form-item label="订单编号：">
            <div>
              {{ data.orderNumber }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 订单状态 -->
          <a-form-item label="订单状态：">
            <div>
              {{ data.orderStatusName }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 订单时间 -->
          <a-form-item label="订单时间：">
            <div>
              {{ data.createTime }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :sm="24" :xs="24">
          <div class="op">
            <a-form-item class="ant-descriptions-title big"
              >物流信息</a-form-item
            >
            <a @click="changeClick(data)">修改</a>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 物流公司 -->
          <a-form-item label="物流公司:" name="expressCompany">
            <div>{{ data.expressCompany }}</div>
          </a-form-item>
          <!-- 物流订单号 -->
          <a-form-item label="物流订单号:" name="expressNo">
            <div>{{ data.expressNo }}</div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :sm="24" :xs="24">
          <div class="op">
            <a-form-item class="ant-descriptions-title big"
              >收货信息</a-form-item
            >
            <a @click="receiptClick(data)">修改</a>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <a-form-item label="收货人:" name="receiptName">
            <div>{{ data.receiptName }}</div>
          </a-form-item>
          <a-form-item label="收货人电话:" name="receiptPhone">
            <div>{{ data.receiptPhone }}</div>
          </a-form-item>
          <a-form-item label="收货人地区:" name="receiptAreacode">
            <div>{{ data.receiptAreacode }}</div>
          </a-form-item>
          <a-form-item label="收货人详细地址:" name="receiptDetail">
            <div>{{ data.receiptDetail }}</div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as dictDataApi from '@/api/sys/dictData'
export default {
  name: 'order-Detail',
  emits: ['update:visible', 'done'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      logDict: []
    }
  },
  created() {
    this.queryDataStates()
  },
  methods: {
    save() {},
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 物流信息修改
    changeClick(row) {
      //   子组件调用父组件的方法里的方法
      this.$parent.deliverClick(row)
      this.$emit('done')
    },
    // 收货信息修改
    receiptClick(row) {
      //   子组件调用父组件的方法里的方法
      this.$parent.addressClick(row)
      this.$parent.reload()
    //   this.$parent.openDetail(row)
    //   this.$emit('done')
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('logCompany')
        .then((res) => {
          if (res.code === 0) {
            this.logDict = res.data
            this.logDict.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
.op {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
